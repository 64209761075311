(function ($) {
  function _launch(alreadyExists) {
    var rendered = site.template.get({
      name: 'product_fave_overlay_v1',
      data: { already_exists: alreadyExists }
    });

    generic.overlay.launch({
      content: rendered,
      width: 391,
      height: 304,
      cssClass: 'product-fave-overlay-cbox'
    });
  }
  $(document).on('addToWishlist.exists', function () {
    _launch(true);
  });

  $(document).on('addToWishlist.success', function () {
    _launch(false);
  });
})(jQuery);
